import React, { Component } from 'react';
import { Logo } from './modules/ui';
 
export default class Header extends Component {

    render(){
        return ( 
            <div className="flex flex-center flex-abs-center global__header">                
                <Logo />
            </div>
        )
    }
}