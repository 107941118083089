import React, { Component } from 'react';
// import {Link} from 'react-router-dom';
import { fetchData } from '../../utils/common';
import {runInAction, observable} from 'mobx';
import {observer} from 'mobx-react';
import Loop from '../posts/loop';
import { Store } from '../../stores/storesContext';
import {PreloaderWithWrapper} from '../modules/ui';

@observer
class SlideShow extends Component {
   
    @observable hasLoaded = false;
    @observable filteredContent = {};
    limit = null;
    content = null;
    endpoint = '';

    async componentDidMount(){
        const domain = this.context.DomainStore.DOMAIN;
        this.endpoint = `${domain}/index.php?rest_route=/wp/v2/posts&_embed=wp:featuredmedia&per_page=20`;


        // await this.getCategoriesInfo();
        await this.getContent(this.endpoint);
        this.contructHeadData();
    }

    componentWillUnmount(){
        this.clearHeaderDataAfterUnmounting();
    }

    clearHeaderDataAfterUnmounting(){
        if(this.oldHead){
            const head = document.getElementsByTagName('head')[0];
            const defaultHeader = this.oldHead;
            const doc = document.getElementsByTagName('html')[0];
            doc.replaceChild(defaultHeader, head);
            this.oldHead = null;
        }
    }

    contructHeadData(){
        if(this.hasLoaded && this.content){
            const headData = this.context.DomainStore.getDefaultMetaData();
            const head = document.getElementsByTagName('head')[0];
            this.oldHead = head.cloneNode(true);
            head.insertAdjacentHTML('beforeend', headData.content);
        }
    }

    /**
     * Get the content for the category
     * @param {*} endpoint 
     */
    async getContent(endpoint){
        let data = null;
        data = await fetchData(endpoint);

        if(data){
            this.content = data;
            this.filteredContent = this.content;
            runInAction(() => this.hasLoaded = true );
        }
    }

    render(){

        return (
            <section className="page page--loop">
                {!this.hasLoaded &&
                    <PreloaderWithWrapper />
                }
                {this.hasLoaded &&
                    <div className="animated-wrapper loop__wrapper">
                        <Loop 
                            items={this.filteredContent} 
                            endpoint={this.endpoint} 
                            limit={this.limit}
                            additionalData={null}
                        />
                    </div>
                }
            </section>
        )
    }
}

SlideShow.contextType = Store;
export default SlideShow;