import React, { Component } from 'react';
import {observer} from 'mobx-react';
import { parseHtmlEnteties } from '../../utils/common';

@observer
class LoopPost extends Component {

    render(){
        const createMarkup = () => ({__html: this.props.excerpt});
        return (
            <article className="sm-33 loop loop__post">
                <figure className="post__thumb">
                    <img src={this.props.thumb} alt={this.props.title} />
                </figure>
                <h2 className="flex flex-abs-center post__title">
                    {parseHtmlEnteties(this.props.title)}
                </h2>
                {this.props.excerpt && <section className="post__excerpt" dangerouslySetInnerHTML={createMarkup()} />}
            </article>
        )
    }
}


export default LoopPost;