import React, { Component } from 'react';
import {observer} from 'mobx-react';
import SlideShow from './categories/slideshow';
import Overlay from './overlay';


@observer
class Home extends Component {

    constructor(props,context){
        super(props, context);
        this.endpoint = '//';
    }


    render(){
        return(
            <>
                <SlideShow />
                <Overlay />
            </>
        )
    }

}

export default Home;