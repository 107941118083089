import React, { Component } from 'react';
import {observer} from 'mobx-react';
import LoopPost from './loopPost';
import { observable } from 'mobx';


@observer
class Loop extends Component {

    @observable count = 1;

    getCorrectImageSize(index){
        const imageArr = this.props.items[index]._embedded["wp:featuredmedia"][0];
        let ml, md = null;

        if(imageArr){
            ml = this.props.items[index]._embedded["wp:featuredmedia"][0]?.media_details?.sizes?.large?.source_url;
            md = this.props.items[index]._embedded["wp:featuredmedia"][0]?.media_details?.sizes?.medium?.source_url;
        }
        if(!ml && !md){
            return imageArr.source_url
        }
        else {
            return (ml) ? ml : md;
        }
    }

    render(){
        
        const loopList = () => (
           this.props.items.map((item, index) => (
                <LoopPost 
                    title={item.title.rendered}
                    excerpt={item.excerpt.rendered}
                    thumb={this.getCorrectImageSize(index)}
                    link={item.slug}
                    key={index}
                /> 
            ))
        )

        return (
            <section className="loop loop--posts">
                <main className="posts">
                        {this.props.additionalData &&
                        <CategoryInfo description={this.props.additionalData} />
                        }
                        {loopList()}
                </main>
            </section>
        )
    }
}


/**
 * Display the category's description - optiona;
 * @param {*} props 
 */
const CategoryInfo = (props) => {
    const createMarkup = () => ({__html: props.description});

    return(
        <article className="loop loop__post loop__post--txt loop__post--desc">
            <div dangerouslySetInnerHTML={createMarkup()} />
        </article>
    )
}

export default Loop;