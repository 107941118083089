import {observable, action} from 'mobx';

export default class UIStore {

    @observable isModalOpen = false;

    CATEGORY_DATA = null;

    constructor(rootStore) {
        this.rootStore = rootStore
    }

    @action
    setModalVisibility(val){
        this.isModalOpen = val;
    }

}