/**
 * Generic method to fetch async data
 * @param {*} endpoint 
 */
const fetchAsync = async (endpoint) => {
    // await response of fetch call
    const response = await fetch(endpoint, {mode: 'cors'});
    const data = await response.json();

    return data;
}

export const fetchData = async (data) => {
    try {
        return await fetchAsync(data);
    } 
    catch(e){
        console.log(e);
        throw e;
    }
}

export const parseHtmlEnteties = (str) => (
    str.replace(/&#([0-9]{1,4});/gi, (match, numStr) => {
        const num = parseInt(numStr, 10); // read num as normal number
        return String.fromCharCode(num);
    })
)