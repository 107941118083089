import React, {Component} from 'react';
import { Route, Switch } from "react-router";
import { Home } from '../';
import { Store } from '../../stores/storesContext';

class Wrapper extends Component {

  render(){
    return (      
        <div className="animated-wrapper main-wrapper">
            <Switch>
                <Route exact path="/" component={Home} />
            </Switch>
        </div>
    );
  }
}


Wrapper.contextType = Store;
export default Wrapper;

