import React, {Component} from 'react';
import './assets/scss/style.scss';
import Footer from './components/footer';
import Header from './components/header';
import Wrapper from './components/wrapper/wrapper';

export default class App extends Component {

  render(){

    return (      
        <div className="App">
          <Header />
          <Wrapper />
          <Footer />
        </div>
    );
  }
}
