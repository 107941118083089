import React, { Component } from 'react';
import {observable, action} from 'mobx';
import {observer} from 'mobx-react';
import classNames from 'classnames';
import diamond from '../assets/img/diamond.png';


@observer
class Overlay extends Component {
   
    @observable isMenuOpen = false;

    content = null;

    constructor(props){
        super(props);
        this.handleMenuClick = this.handleMenuClick.bind(this);
    }


    @action
    handleMenuClick(e){
        e.preventDefault();
        this.isMenuOpen = !this.isMenuOpen;
    }

    @action
    closeMenu(){
        this.isMenuOpen = !this.isMenuOpen;
    }


    render(){
        const menuClass = classNames('main-nav',  {
            'main-nav--open': this.isMenuOpen,
        }); 

        const toggleClass = classNames('menu__toggle',  {
            'menu__toggle--open': this.isMenuOpen,
        }); 

        return (
            <>
                <div className="text-center overlay-wrapper">
                    <img className="diamond" src={diamond} alt="Diamond" />

                    <h1>WIll You Marry me?</h1>
                    <span className="btn"
                        onClick={(e) => this.handleMenuClick(e)}
                     >
                        YES
                    </span>
                    <span className="btn btn--no" disabled>NO</span>
                </div>

                <nav className={menuClass}>
                    <span className={toggleClass} onClick={(e) => this.handleMenuClick(e)}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </span>

                    <h2>CLOSE YOUR EYES</h2>
                </nav>
            </>
        )
    }
}


export default Overlay;