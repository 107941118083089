// import {observable, action} from 'mobx';
// import {observer} from 'mobx-react';
// import { fetchData } from '../utils/common';

export default class InitStore {

    constructor(rootStore) {
        this.rootStore = rootStore;
        this.init();
    }

    // @action
    // async gatherData(){
    //     if(!this.data.length){
    //         const domain = this.rootStore;
    //         debugger;
    //         const endpoint = `${domain}/wp-json/wp/v2/categories/`;
    //         const response = await this.getContent(endpoint);
    //     }
    // }

    // /**
    //  * Fetch the remote data
    //  * @param {*} endpoint 
    //  */
    // async getContent(endpoint){
    //     return fetchData(endpoint);
    // }


    async init(){
        // await this.gatherData();
    }

}
